<template>
    <div class="CustomerCreate">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never">
            <el-button type="primary" @click="handleSave" :disabled="disableSave" size="small">保存</el-button>
            <el-button type="primary" @click="handleAdd" size="small">添加</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-form
                @submit.native.prevent
                :inline="true"
                :model="form"
                :rules="rules"
                ref="createForm"
                label-width="80px"
                size="small"
            >
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.tableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="440"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" />
                    <el-table-column label="客户名称" width="160">
                        <template slot-scope="scope">
                            <el-form-item :prop="'tableData.' + scope.$index + '.names'" :rules="rules.names">
                                <el-input v-model="scope.row.names" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="联系人" width="190">
                        <template slot-scope="scope">
                            <el-form-item :prop="'tableData.' + scope.$index + '.linkMans'" :rules="rules.linkMans">
                                <el-input v-model="scope.row.linkMans" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="手机号" width="160">
                        <template slot-scope="scope">
                            <el-form-item :prop="'tableData.' + scope.$index + '.mobiles'" :rules="rules.mobiles">
                                <el-input v-model="scope.row.mobiles" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="地址" width="160">
                        <template slot-scope="scope">
                            <el-form-item>
                                <el-input v-model="scope.row.addresses" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import { mobileValidateRule } from 'js/validate/ValidateCommonRule';
import { postAfterConfirmed } from 'request/http';

export default {
    name: 'CustomerCreate',
    data() {
        return {
            form: {
                tableData: [],
            },
            rules: {
                names: { required: true, message: '请输入客户姓名', trigger: ['change', 'blur'] },
                linkMans: { required: true, message: '请输入联系人', trigger: ['change', 'blur'] },
                mobiles: mobileValidateRule(),
            },
        };
    },
    computed: {
        //禁用/启用按钮
        disableSave() {
            return (this.form.tableData || []).length <= 0;
        },
    },
    methods: {
        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid || this.disableSave) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                await postAfterConfirmed('/advert/customer/createExtend', {
                    names: this.form.tableData.map((e) => e.names),
                    linkMans: this.form.tableData.map((e) => e.linkMans),
                    addresses: this.form.tableData.map((e) => e.addresses || [' ']),
                    mobiles: this.form.tableData.map((e) => e.mobiles),
                });
                this.goBackAndReload();
            });
        },
        handleAdd() {
            this.form.tableData.push({
                names: '',
                linkMans: '',
                mobiles: '',
                addresses: '',
            });
        },
        handleDelete(index) {
            this.form.tableData.splice(index, 1);
        },
    },
};
</script>
